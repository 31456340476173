<template>
  <div class="container">
    <div class="title">Contact Litely Team</div>
    <div class="subtitle">
      Got something you want to talk about? Contact us and we promise to get
      back to you as soon as we can.
    </div>
    <div class="Help">Help & Support</div>
    <div class="help_detail">
      If you have any questions or suggestions, please contact us at
      <p style="color: rgba(248, 90, 74, 1)" class="email">
        support@mail.litely.life
      </p>
    </div>
    <div class="press">Press</div>
    <div class="press_detail">
      For press or media inquiries, please visit our press page (
      <span @click="press_page_clicked" style="cursor: pointer">press page</span>
      ) or contact us at
      <span class="email" style="color: rgba(248, 90, 74, 1)">press@litelypress.com</span>
      or
      <span class="email" style="color: rgba(248, 90, 74, 1)">press@litelypr.com</span>
      directly.
    </div>
    <div class="Partnership">Partnership</div>
    <div class="Partnership_detail">
      Interested in partnering with Litely?

      <span class="email" style="color: rgba(248, 90, 74, 1)">marketing@mail.litely.life</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$store.commit("setIsApp", false);
  },
  mounted() { },
  components: {},
  computed: {},
  methods: {
    press_page_clicked() {
      this.$router.push("/press");
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/css/less_custom.less";

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  height: 100vh;
  padding-left: 20%;
  padding-right: 20%;
  margin-bottom: 200px;

  .title {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 700;
    color: rgba(23, 24, 27, 1);
  }

  .subtitle {
    font-size: 20px;
    font-weight: 500;
    color: rgba(23, 24, 27, 1);
  }

  .Help {
    margin-top: 60px;
    // margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
    color: rgba(23, 24, 27, 1);
    align-self: flex-start;
  }

  .help_detail {
    font-size: 20px;
    font-weight: 500;
    color: rgba(23, 24, 27, 1);
    text-align: left;
    align-self: flex-start;
  }

  .press {
    margin-top: 30px;
    // margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
    color: rgba(23, 24, 27, 1);
    align-self: flex-start;
  }

  .press_detail {
    font-size: 20px;
    font-weight: 500;
    color: rgba(23, 24, 27, 1);
    text-align: left;
    align-self: flex-start;

    span {
      color: rgba(248, 90, 74, 1);
    }
  }

  .Partnership {
    margin-top: 30px;
    // margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
    color: rgba(23, 24, 27, 1);
    align-self: flex-start;
  }

  .Partnership_detail {
    font-size: 20px;
    font-weight: 500;
    color: rgba(23, 24, 27, 1);
    text-align: left;
    align-self: flex-start;
  }
}

@media screen and (max-width: @MobileMaxWidth) {
  .container {
    width: 90%;
    height: 100%;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 100px;

    .title {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 30px;
    }

    .subtitle {
      font-size: 14px;
      width: 90%;
    }

    .Help {
      margin-top: 40px;
      // margin-bottom: 10px;
      font-size: 20px;
    }

    .help_detail {
      font-size: 14px;
    }

    .press {
      margin-top: 20px;
      // margin-bottom: 10px;
      font-size: 20px;
    }

    .press_detail {
      font-size: 14px;
    }

    .Partnership {
      margin-top: 20px;
      // margin-bottom: 10px;
      font-size: 20px;
    }

    .Partnership_detail {
      font-size: 14px;
    }
  }
}
</style>
